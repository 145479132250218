import { storeToRefs } from 'pinia';

export default function () {
    const store = usePageTranslationsStore();
    const { pageTranslations } = storeToRefs(store);

    function updatePageTranslations(translations) {
        pageTranslations.value = translations;
    }

    return {
        pageTranslations,
        updatePageTranslations,
    };
}
