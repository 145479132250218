import { defineStore } from 'pinia';

const usePageTranslationsStore = defineStore('pageTranslationsStore', () => {
    const pageTranslations = ref({});
    return {
        pageTranslations,
    };
});

export { usePageTranslationsStore };
